#betweenbanners {
    height: 88%;
    width: 100%;
    display: table;
}

#logobanner {
    max-height: 20%;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    overflow-y: none;
}

#logocentered {
    width: 30%;
    max-width: 300px;
    display: table;
    margin: 0 auto;
}

#d2clogo {
    width: 100%;
}

#d2ctitle {
    color: $primaryColor;
    font-weight: 900;
    width: 100%;
    height: 40px;
    font-size: 2.9vw;
    overflow: none;
}